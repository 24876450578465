import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle'
// import LanguagePicker from '../../LanguagePicker';
import './Legal.css'

export const PrivacyNoticePage = () =>{
  const { t } = useTranslation();

  return (
    <>
    <div className='header'>
      {/* <LanguagePicker defaultLanguage={'en'} /> */}
    </div>
    <PageTitle title={t('legalLinks.privacyNotice')} />
    <div className="legal-page-content">
      <h1>Privacy Notice Huez AB</h1>
      <p>Last Updated: May 14th, 2024</p>
      <p>This privacy notice sets out how Huez AB, incorporated in Sweden with company number 559456-3859 (“Huez”), collects and otherwise processes your personal data.</p>
      <p>If you have any questions about this notice or how we process your data, or want to exercise your rights, please contact us on <a className='text-green' href="mailto:info@huez.se">info@huez.se</a>.</p>

      <h2>1. Data controller</h2>
      <p>Huez is the data controller responsible for the processing of your personal data under EU Regulation 2016/679 (the “GDPR”).</p>

      <h2>2. Personal data we collect</h2>
      <p>We collect and process the following types of personal data:</p>
      <ul>
          <li><strong>Contact information:</strong> Name, email address, postal address, phone number, etc.</li>
          <li><strong>Usage data:</strong> Username, password, account and content preferences, how you navigate in our services, which functions you use, etc.</li>
          <li><strong>Transaction and payment Information:</strong> Purchases or selling you make, credit/debit card details, account information, and other payment information.</li>
          <li><strong>Technical data and device information:</strong> IP address, browser type, device information, errors, response time, etc.</li>
          <li><strong>Messages sent and received within the service:</strong> Messages and contacts you have with Huez or other users on our platform.</li>
      </ul>

      <h2>3. How we use your personal data</h2>
      <p>In this section, you can read about for which purposes we use your data, which types we use for each purpose, based on which so-called “legal basis,” and how long we perform the purpose.</p>
      <table>
          <tr>
              <th>Purpose</th>
              <th>Types of personal data</th>
              <th>Legal basis</th>
              <th>When we stop processing your data</th>
          </tr>
          <tr>
              <td>To carry out the agreement with you, and manage our customer relationship.</td>
              <td>
                  <strong>Contact information</strong> (from you)<br/>
                  <strong>Usage data</strong> (from our services)<br/>
                  <strong>Transaction and payment Information</strong> (from you and payment partners)<br/>
                  <strong>Technical data and device information</strong> (from our services and your device)<br/>
                  <strong>Messages sent and received within the service</strong> (from our services)
              </td>
              <td>This processing is necessary for Huez to deliver our services to you (Article 6(1)(b) GDPR).</td>
              <td>When you or we terminate the agreement with you.</td>
          </tr>
          <tr>
              <td>To ensure network security, and to keep our services safe and secure.</td>
              <td>All types listed in section 2.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). We have determined this is a legitimate interest and ensure our processing is necessary to achieve this purpose.</td>
              <td>As long as you use our services.</td>
          </tr>
          <tr>
              <td>To verify your identity and prevent fraud in our services.</td>
              <td>All types listed in section 2.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). We have determined this is a legitimate interest and ensure our processing is necessary to achieve this purpose.</td>
              <td>As long as you use our services.</td>
          </tr>
          <tr>
              <td>To calculate payments commission to suppliers and users.</td>
              <td>
                  <strong>Contact information</strong> (from you)<br/>
                  <strong>Usage data</strong> (from our services)<br/>
                  <strong>Transaction and payment Information</strong> (from you and payment partners)
              </td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). We have determined this is a legitimate interest and ensure our processing is necessary to achieve this purpose.</td>
              <td>Until payments have been made.</td>
          </tr>
          <tr>
              <td>Perform analytics, service improvement analysis, and analyze usage behavior.</td>
              <td>All types listed in section 2.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). We have determined this is a legitimate interest and ensure our processing is necessary to achieve this purpose.</td>
              <td>As long as you use our services, and thereafter as long as we have data in our systems if we have identified you pose a risk of fraud.</td>
          </tr>
          <tr>
              <td>Share your data with the recipients set out in Section 4.</td>
              <td>All types listed in section 2.</td>
              <td>Depends on the recipient.</td>
              <td>As long as we have data in our systems.</td>
          </tr>
          <tr>
              <td>To send marketing.</td>
              <td>
                  <strong>Contact information</strong> (from you)
              </td>
              <td>
                  The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). We have determined this is a legitimate interest and ensure our processing is necessary to achieve this purpose.<br/>
                  You might also have provided consent to this (Article 6(1)(a) GDPR).
              </td>
              <td>As long as you use our services, or until you object.</td>
          </tr>
          <tr>
              <td>To comply with laws, such as bookkeeping and accounting laws in Sweden.</td>
              <td>
                  <strong>Contact information</strong> (from you)<br/>
                  <strong>Usage data</strong> (from our services)<br/>
                  <strong>Transaction and payment Information</strong> (from you and payment partners)<br/>
                  <strong>Messages sent and received within the service</strong> (from our services)
              </td>
              <td>To comply with law (Article 6(1)(c) GDPR)</td>
              <td>Seven years after the year during which a transaction has been made.</td>
          </tr>
          <tr>
              <td>To protect Huez from legal claims.</td>
              <td>All types listed in section 2.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). We have determined this is a legitimate interest and ensure our processing is necessary to achieve this purpose.</td>
              <td>Up to ten years after our services have been used.</td>
          </tr>
      </table>

      <h2>4. Data recipients</h2>
      <p>We may share your personal data with the following data recipients.</p>
      <table>
          <tr>
              <th>Recipients</th>
              <th>Description</th>
              <th>Legal basis</th>
          </tr>
          <tr>
              <td>Other users</td>
              <td>Huez might share your personal data with other users to carry out a transaction, or in line with performing the service.</td>
              <td>This processing is necessary for Huez to deliver our services to you (Article 6(1)(b) GDPR).</td>
          </tr>
          <tr>
              <td>Suppliers such as IT providers or consultants</td>
              <td>These companies provide functionality which Huez needs to provide its services.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR).</td>
          </tr>
          <tr>
              <td>Payment partners</td>
              <td>Huez uses payment partners to offer payments in its services. Such partners will independently own and use the information they receive.</td>
              <td>This processing is necessary for Huez to deliver our services to you (Article 6(1)(b) GDPR).</td>
          </tr>
          <tr>
              <td>Authorities</td>
              <td>Huez might share personal data with authorities such as the police to keep its services secure.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). Huez might also have to share personal data to comply with law (Article 6(1)(c) GDPR).</td>
          </tr>
          <tr>
              <td>Divestment or selling of assets.</td>
              <td>Huez might divest part or whole of its business, or be acquired, meaning that your personal data will be shared with the buyer.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR).</td>
          </tr>
          <tr>
              <td>Social media</td>
              <td>If you interact with us on social media, your information will be shared with such social media company.</td>
              <td>The processing is based on a legitimate interest of Huez, after balancing against your interest (Article 6(1)(f) GDPR). This processing might also be necessary for Huez to deliver our services to you (Article 6(1)(b) GDPR).</td>
          </tr>
      </table>

      <h2>5. Data Retention</h2>
      <p>We will retain your personal data only for as long as necessary to fulfill the purposes outlined in this notice unless a longer retention period is required or permitted by law. Especially the Swedish bookkeeping law requires accounting data to be kept for seven years after the year a transaction was made.</p>
      <p>Swedish statutory limitation law also prescribes that Huez needs to keep personal data necessary to protect it from claims for ten years after our service was used.</p>

      <h2>6. Your Rights</h2>
      <p>You have the following rights regarding your personal data. You can exercise them by contacting us through the email address listed on the top of this notice.</p>
      <ul>
          <li>The right to access and receive a copy of your personal data, as well as receive specific information on how we have processed it.</li>
          <li>The right to rectify any inaccurate or incomplete personal data.</li>
          <li>The right to erase your personal data (“right to be forgotten”), unless Huez is unable to do this based on legal obligation or due to compelling legitimate grounds.</li>
          <li>Right to object to the processing of your personal data. You have the right to object to processing of your personal data which is based on our legitimate interest (Article 6(1)(f) GDPR), by referencing your specific circumstances. You can always object to our use of your personal data for marketing purposes.</li>
          <li>The right to restrict the processing of your personal data. You can request this, for example, while we administrate you exercising your right to object, or your right to data deletion.</li>
          <li>The right to data portability, i.e., to receive your personal data in an electronic/machine-readable format.</li>
          <li>Right to withdraw your consent. This means that we will stop processing your data for purposes on which we rely on your consent.</li>
          <li>To submit a complaint to the Swedish Data Protection Authority, <em>Integritetsskyddsmyndigheten</em>.</li>
      </ul>

      <h2>7. When we can transfer your personal data outside of the EEA, and how we protect it</h2>
      <p>We always strive to process your personal data within the European Economic Area (“EEA”). However, sometimes we might need to transfer your data also outside this region.</p>
      <p>When we do this, we make sure the data is kept equally secure as in the EEA, and that protective measures are implemented as required. Such measures might be technical in nature such as encryption or pseudonymisation, but we might also rely on the following administrative security measures, as allowed by the GDPR:</p>
      <ul>
          <li>if the European Commission has decided that the country outside of the UK/EEA to which your personal data are transferred has an adequate level of protection, which corresponds to the level of protection afforded by the GDPR, or</li>
          <li>the European Commission’s standard clauses have been entered into between Huez and the recipient of the personal data outside the EEA. In these cases, we also assess whether there are laws in the recipient country that affects the protection of your personal data, or</li>
          <li>that the transfer is covered by the EU-US Data Privacy Framework.</li>
      </ul>
      <p>If you want a copy of any of these instruments you can contact us and request them.</p>

      <h2>8. Changes to this privacy notice</h2>
      <p>We reserve the right to update or modify this privacy notice at any time. You should therefore consult this notice regularly when you use our services.</p>

      <center><p><h1>***</h1></p></center>
    </div>
    </>
  )
}