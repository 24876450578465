import React from 'react';
import './SocialLinks.css'
import { SocialIcon } from 'react-social-icons';

const urls = [
  'https://www.facebook.com/profile.php?id=61558736829662',
  'https://www.instagram.com/huez.app',
  'https://x.com/HuezApp'
]

const SocialLinks: React.FC = () => {

  return (
    <div className='social-links'>
      {
        urls.map((url)=>(
          <SocialIcon key={url} className="social-icon" url={url} target='blank' />
        ))
      }
    </div>
  );
};

export default SocialLinks;