import React, {useEffect, useState, useCallback} from "react";
import { useTranslation } from 'react-i18next';
import './Form.css'
import { FormObject } from "./app-types";

interface HubspotContactFormProps {
  form: FormObject;
  id: string;
  closeModal: any
}

const HubspotContactForm: React.FC<HubspotContactFormProps> = ({ form, id, closeModal }) => {
    const portalId = "144274802";
    const { t } = useTranslation();
    let [loaded, setLoaded] = useState(false)

    const close = useCallback(() => {
        closeModal();
        setLoaded(false);
    }, [closeModal]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            const hbspt = (window as any).hbspt;
            // @TS-ignore
            if (hbspt) {
                // @TS-ignore
                hbspt.forms.create({
                    portalId: portalId,
                    formId: form.formId,
                    target: `#${id}`,
                    onFormSubmit: function() {
                        close()
                    } 
                })
                setLoaded(true)
            }
        });
    }, [form, id, close]);

    return (
        <div className="form-container">
            {/* <span className="text-eggplant close-button material-symbols-outlined cursor-pointer" onClick={closeModal}>Close</span> */}
            <p className="form-prompt text-eggplant">{t(`formCTA.${form.audienceName}.title`)}</p>
            <p className="text-eggplant form-description">{t(`formCTA.${form.audienceName}.description`)}</p>
            <div className="signup-form" id={id}></div>
            {!loaded && <center><span className="loader"></span></center>}
            <br />
            {loaded && <button className="hs-button close-button" onClick={close}>Exit</button>}
        </div>
    );

}

export default HubspotContactForm;