import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle'
// import LanguagePicker from '../../LanguagePicker';
import './Legal.css'

export const TermsOfServicePage = () =>{
  const { t } = useTranslation();

  return (
    <>
    <div className='header'>
      {/* <LanguagePicker /> */}
    </div>
    <PageTitle title={t('legalLinks.termsOfService')} />
    <div className="legal-page-content">
    <h1>Huez AB Terms of Service</h1>
    <p>Last Updated: May 14th, 2024</p>
    <p>These Terms of Service ("Terms") govern your access to and use of our Service such as the Huez platform, website, and connected services, provided by Huez AB, incorporated in Sweden with company number 559456-3859 (“Huez”, “us”, “we”). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.</p>

    <h2>1. The Service and definitions</h2>
    <p>Huez provides a platform where Works of Art can be presented online, procured for showings in physical spaces, and sold/auctioned online. The Service also includes presenting relevant personalized content based on your previous usage and interests.</p>
    <p><strong>Definitions</strong></p>
    <p><strong>Buyer:</strong> Anyone buying Works of Art through the Service.</p>
    <p><strong>Work(s) of Art:</strong> Any content such as physical pictures, physical paintings, statues or other creative products uploaded to the service and/or any physical or digital representation thereof.</p>
    <p><strong>Service:</strong> Any tool/application that is provided by Huez to facilitate serving Creators, Venues, and End Users such as the Huez app(s) and website(s).</p>
    <p><strong>Creator:</strong> Any user uploading Works of Art to the Service.</p>
    <p><strong>Venue:</strong> Any user procuring the right to present any Work of Art in a physical space.</p>
    <p><strong>End Users:</strong> Anyone who browses and/or uses the Service, including any Buyer, Creator, and/or Venue.</p>

    <h2>2. Eligibility</h2>
    <p>You must be at least 18 years old and have the legal capacity to enter into these Terms. By accessing or using the Service, you represent and warrant that you meet these eligibility requirements.</p>

    <h2>3. End User obligations</h2>
    <p>3.1. You agree to provide us accurate, current, and complete information about you and any Work of Art. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
    <p>3.2. You agree to notify Huez immediately of any unauthorized use of your account or any other breach of security. Huez reserves the right to suspend or terminate your account at any time for any reason, without prior notice or liability.</p>
    <p>3.3. You agree to use the Service only for lawful, intended purposes and in accordance with these Terms. You may not use the Service in any manner that could damage, disable, overburden, or impair the Service or Huez, or interfere with any other party's use and enjoyment of the Service.</p>
    <p>3.4. You may not scrape, modify, reproduce, distribute, or create derivative works based on the Service.</p>
    <p>3.5. You agree to not use the Service to transmit or distribute any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable, as determined by Huez in its sole discretion.</p>
    <p>3.6. You agree to not copy or download Works of Art displayed in the Service without permission from its Creator.</p>
    <p>3.7. Where applicable, you agree to pay any fees due to Huez, in the form of relevant subscription fees and commission.</p>

    <h2>3a. Creator-specific obligations</h2>
    <p>These obligations apply if you are a Creator, posting Works of Art in the Service, or enabling the distribution of Works of Art.</p>
    <p>3a.1. You agree to give the correct representation of your artwork, and that you are the original creator, owning all intellectual rights to any Work of Art submitted in the Service. You should also provide a certificate of authenticity bearing the same signature on the Work of Art as part of the sale to any Buyer. Huez can provide such a service for a separate fee.</p>
    <p>3a.2. You agree to not publish any Work of Art to the Service which you do not own the rights to, and not publish any digital art, or any AI-generated art, and understand that Huez may remove from the Service any Work of Art which intellectual ownership of is in dispute or violates these Terms, based on Huez’ sole discretion.</p>
    <p>3a.3. You agree to indemnify Huez for any claims that your Work of Art infringe on a third party’s intellectual property rights.</p>
    <p>3a.4. You agree to make sure that any Work of Art sent by mail or transported to a Buyer is subject to suitable insurance and transport documentation so that you can show that the Art of Work has been dispatched to the Buyer, and subsequently claimed by the Buyer. You agree to provide Huez evidence thereof upon request. In case of a dispute of receival by a Buyer, you will not be paid by Huez unless you can show the Buyer has claimed the Work of Art.</p>
    <p>3a.5. Displaying Works of Art at Venues is completely free as long as they are (i) displayed through the Service and/or at a Venue for a minimum of 30 days, or for a shorter period than that provided the Works of Art are sold through the Service. If a Work of Art is not displayed for the minimum time and then sold outside of the Service, you are obliged to pay the dictated fees for displaying your Works of Art for the period incurred.</p>

    <h2>3b. Venue-specific obligations</h2>
    <p>These obligations apply if you are a Venue presenting Works of Art.</p>
    <p>3b.1. You agree to ensure that any Works of Art procured for display through the Service and which you have not purchased are subject to suitable insurance against damages or loss by, for example, vandalism, theft, or fire. You agree to present evidence of such insurance upon Huez’ request. You are responsible for any damages to any Works of Art displayed by you.</p>
    <p>3b.2. You agree to not copy or create derivatives of any Work of Art procured for display through the Service, and which you have not purchased.</p>
    <p>3b.3. You agree not to sell any Work of Art outside of the Service or engage in any business activities with any Creator outside of the Service.</p>
    <p>3b.4. You should ensure that any Work of Art is displayed in a public setting, and QR codes or promotional materials are prominently displayed next to the Works of Art being displayed.</p>

    <h2>4. Payments by Huez</h2>
    <p>4.1. Huez will collect any payments from any Buyer for any Works of Art.</p>
    <p>4.2. Huez will then pay the Creator and the Venue (as applicable) 30 calendar days after receiving the money from the Buyer, after deducting Huez’ fees. The payments will be deposited to your Huez account or paid out directly as indicated by you.</p>

    <h2>5. Intellectual Property</h2>
    <p>5.1. The Service and its original content, features, and functionality are owned by Huez and are protected by copyright, trademark, and other intellectual property laws. Huez is the sole owner of any intellectual property rights of the Service.</p>
    <p>5.2. By submitting or posting any Work of Art on or through the Service, you grant Huez a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to, for the purpose of displaying and marketing the Work of Art or the Service (both physically and digitally), use, reproduce, modify, adapt, publish, translate, distribute, and display such content worldwide in any form, media, or technology.</p>

    <h2>6. Limitation of Liability</h2>
    <p>6.1. Huez is not liable for any lost, stolen, or otherwise damaged Work(s) of Art, or for any non-payment of any Buyer, or non-delivery of a Creator. Access to the Service is provided on an “as is” and “as available” basis, without warranty of any kind. Huez disclaims all representations, warranties, and conditions, express, implied, or statutorily.</p>
    <p>6.2. To the fullest extent permitted by applicable law, in no event shall Huez, its affiliates, officers, directors, employees, agents, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to damages for lost profits, lost data, or business interruption, arising out of or in connection with your use of the Service.</p>
    <p>6.3. Huez' total liability to you for all claims arising out of or related to these Terms or the Service shall be the lowest of either the amount paid by you, if any, to Huez for the use of the Service the last twelve (12) months, or SEK 100.</p>

    <h2>7. Indemnification</h2>
    <p>You agree to indemnify and hold harmless Huez, its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or related to your use of the Service or any violation of these Terms.</p>

    <h2>8. Data processing</h2>
    <p>You can find information on how your data is processed in our privacy notice, available on our website.</p>

    <h2>9. Governing Law</h2>
    <p>These Terms shall be governed by and construed in accordance with the laws of Sweden, without regard to its conflict of law provisions. Any dispute arising out of or in connection with these Terms shall be submitted to the exclusive jurisdiction of the courts of Sweden.</p>

    <h2>10. Changes to Terms</h2>
    <p>Huez reserves the right to modify or replace these Terms at any time, in its sole discretion. If a revision is material, Huez will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Huez’ sole discretion. It is important you regularly read these Terms when you use the Service.</p>

    <h2>11. Assignment or transfer of rights</h2>
    <p>You agree that Huez may assign to any person or entity any of our rights under the agreement to which these Terms apply.</p>
    <p>You may not transfer or assign any rights or obligations you have under these Terms. To the extent not otherwise already permitted by applicable law, we may transfer or assign these terms or any right or obligation under these terms at any time provided that such transfer does not alter your rights and obligations under these terms to your detriment.</p>
    <p>You acknowledge and agree that, when payments to Huez are due, Huez has the sole right to receive payment from you, and such right may be assigned, pledged and/or transferred.</p>

    <h2>12. Termination</h2>
    <p>Both you and Huez may terminate these Terms at any time. This shall not affect any obligations arising before such termination. Huez will give you at least two weeks’ notice before termination unless it's based on you breaching these terms in which case the termination will be effective immediately.</p>

    <h2>13. Contact Us</h2>
    <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@huez.se" className='text-green'>info@huez.se</a>.</p>
    <p>By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>

    <center><p>***</p></center>
    </div>
    </>
  )
}