import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import i18n from "./locale/config"
import { useTranslation } from 'react-i18next';
import './LanguagePicker.css'
import ukBlob from './lang-en.svg'
import svBlob from './lang-sv.svg'
import {
  CheckIcon,
} from '@radix-ui/react-icons';

type LanguageOption = {
  code: string;
  name: string;
  icon: string
};

const languages: LanguageOption[] = [
  { code: 'sv', name: 'language.sv', icon: svBlob },
  { code: 'en', name: 'language.en', icon: ukBlob },
];

interface LanguagePickerProps {
  defaultLanguage?: string
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({defaultLanguage = 'sv'}) => {
  const [language, setLanguage] = useState<string>(defaultLanguage);
  const {t} = useTranslation()

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <div className='language-picker'>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className='IconButton' aria-label='Select Language'>
            <img height={24} src={languages.find((lang)=>lang.code === language)?.icon} alt=""/> <span>{t(languages.find((lang)=>lang.code === language)?.name || '')}</span>
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
            <DropdownMenu.RadioGroup value={language} onValueChange={handleLanguageChange}>
              {
                languages.map((option)=>(
                  <DropdownMenu.RadioItem key={option.code} className="DropdownMenuRadioItem" value={option.code}>
                    <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                      <CheckIcon />
                    </DropdownMenu.ItemIndicator>
                    <img width={30} src={option.icon} alt=""/> <span>{t(option.name)}</span>
                  </DropdownMenu.RadioItem>
                ))
              }
            </DropdownMenu.RadioGroup>
            <DropdownMenu.Arrow className="DropdownMenuArrow" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      {/* <select value={language} onChange={handleLanguageChange}>
        {languages.map((option) => (
          <option key={option.code} value={option.code}>
            {t(option.name)}
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default LanguagePicker;