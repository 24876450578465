import React from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next';
import logo from './logo-blob.svg'
import './App.css'
import './fonts/fonts.css'
import {creators, curators, collectors} from './images'
import Form from './Form'
import { Forms } from './constants'
import LanguagePicker from './LanguagePicker';
import {
  OpenInNewWindowIcon
} from '@radix-ui/react-icons';
import SocialLinks from './SocialLinks';
import PageTitle from './components/PageTitle';

Modal.setAppElement('#root')

export default function App() {
  const { t } = useTranslation();
  const form: any = {}
  const [signupModalIsOpen, setSignupModalIsOpen] = React.useState({isOpen: false, form});

  function getRandom (list: Array<any>) {
    return list[Math.floor((Math.random()*list.length))];
  }

  function openSignupModal(form: any) {
    setSignupModalIsOpen({isOpen: true, form});
  }

  function closeModal() {
    setSignupModalIsOpen({isOpen: false, form: signupModalIsOpen.form});
  }

  return (
    <div className="App">
      <PageTitle title={t('homepage.title')} />
      <div className='header'>
        <LanguagePicker />
        <img id="logo" width="100" src={logo} alt="Huez Logo" />
        <SocialLinks />
      </div>
      <div className='container'>
        <Modal
          isOpen={signupModalIsOpen.isOpen}
          className='signup-modal'
          portalClassName='modal-portal'
          overlayClassName='modal-overlay'
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <Form id="waitlist-form" form={signupModalIsOpen.form} closeModal={closeModal}/>
        </Modal>
        <div id="section1" className="section">
          <div className='section-overlay'>
            <div className='text-content'>
              <h1 className='text-white'>{t('homepage.creators.for')}</h1>
              <h1 className='text-white'>{t('homepage.creators.audience')}</h1>
              <span>
                <p className='text-white'>{t('homepage.creators.description')}</p>
                <button className='button bg-green text-eggplant' onClick={openSignupModal.bind(null, Forms.Creators)}>{t('formCTA.modalButton')}</button>
              </span>
            </div>
            <img className='section-bg' src={getRandom(creators)} alt='' />
          </div>
        </div>
        <div id="section2" className="section">
          <div className='section-overlay'>
            <div className='text-content'>
              <h1 className='text-white'>{t('homepage.curators.for')}</h1>
              <h1 className='text-white'>{t('homepage.curators.audience')}</h1>
              <span>
                <p className='text-white'>{t('homepage.curators.description')}</p>
                <button className='button bg-green text-eggplant' onClick={openSignupModal.bind(null, Forms.Curators)}>{t('formCTA.modalButton')}</button>
              </span>
            </div>
            <img className='section-bg' src={getRandom(curators)} alt='' />
          </div>
        </div>
        <div id="section3" className="section">
          <div className='section-overlay'>
            <div className='text-content'>
              <h1 className='text-white'>{t('homepage.collectors.for')}</h1> 
              <h1 className='text-white'>{t('homepage.collectors.audience')}</h1>
              <span>
                <p className='text-white'>{t('homepage.collectors.description')}</p>
                <button className='button bg-green text-eggplant' onClick={openSignupModal.bind(null, Forms.Collectors)}>{t('formCTA.modalButton')}</button>
              </span>
            </div>
            <img className='section-bg' src={getRandom(collectors)} alt='' />
          </div>
        </div>
      </div>
      <div className='legal-links'>
        <button type="button" id="hs_show_banner_button"
        className='cookie-settings small-button bg-eggplant text-green'
        onClick={()=>(function(){
          var _hsp = (window as any)._hsp = (window as any)._hsp || [];
          _hsp.push(['showBanner']);
        })()}
        >
        {t('legalLinks.cookieSettings')}
        </button>
        <a href='/privacy-notice' target='blank'>{t('legalLinks.privacyNotice')} <OpenInNewWindowIcon /></a>
        <a href='/terms-of-service' target='blank'>{t('legalLinks.termsOfService')}<OpenInNewWindowIcon /></a>
      </div>
    </div>
  );
}
