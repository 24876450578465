import collectors1 from './collectors/1.jpg'
import collectors2 from './collectors/2.jpg'
import collectors3 from './collectors/3.jpg'
import collectors4 from './collectors/4.jpg'
import collectors5 from './collectors/5.jpg'
import collectors6 from './collectors/6.jpg'

import curators1 from './curators/1.jpg'
import curators2 from './curators/2.jpg'

import creators1 from './creators/1.jpg'
import creators2 from './creators/2.jpg'

export const collectors = [collectors1, collectors2, collectors3, collectors4, collectors5, collectors6]
export const curators = [curators1, curators2]
export const creators = [creators1, creators2]
